import { AnalyticsModule } from '@analytics-lib/analytics.module'
import { GtmModule } from '@analytics-lib/gtm.module'
import { PortalModule } from '@angular/cdk/portal'
import { registerLocaleData } from '@angular/common'
import {
    HTTP_INTERCEPTORS,
    provideHttpClient,
    withInterceptorsFromDi,
} from '@angular/common/http'
import localeDe from '@angular/common/locales/de'
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatListModule } from '@angular/material/list'
import { MAT_SELECT_CONFIG } from '@angular/material/select'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { MatToolbarModule } from '@angular/material/toolbar'
import { BrowserModule } from '@angular/platform-browser'
import { AuthModule } from '@auth-ui-lib/auth.module'
import { TokenRefreshInterceptorService } from '@auth-util-lib/token-refresh-interceptor.service'
import { environment } from '@env-lib/environment'
import { isDevelopment } from '@env-lib/isDevelopment'
import { ErrorPanelModule } from '@error-ui-lib/error-panel/error-panel.module'
import { GlobalErrorHandler } from '@error-util-lib/global-error-handler.service'
import { GroupManagementStoreModule } from '@group-management-lib/group-management-store.module'
import { MaterialDayjsModule } from '@localization-lib/date-time/material-dayjs/material-dayjs.module'
import { TranslationModule } from '@localization-lib/language/translation.module'
import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { ContentModule } from '@platform-lib/components/content/content.module'
import { FooterModule } from '@platform-lib/components/footer/footer.module'
import { HeaderModule } from '@platform-lib/components/header/header.module'
import { LayoutModule } from '@platform-lib/components/layout/layout.module'
import { SidebarModule } from '@platform-lib/components/sidebar/sidebar.module'
import { IncompatibleBrowserModule } from '@platform-lib/incompatible-browser/incompatible-browser.module'
import { AcceptLanguageInterceptor } from '@platform-lib/interceptors/accept-language.interceptor'
import { TokenAndGroupIdsInterceptor } from '@platform-lib/interceptors/token-and-group-ids.interceptor'
import { CookieModule } from '@platform-lib/legal/cookie/cookie.module'
import { TermsConditionsModule } from '@platform-lib/legal/terms-conditions/terms-conditions.module'
import { NotificationPanelModule } from '@platform-lib/notification/notification-panel/notification-panel.module'
import { NotificationStoreModule } from '@platform-lib/notification/notification-store.module'
import { ButtonModule } from '@shared-ui-lib/button/button.module'
import { NotAllowedComponent } from '@shared-ui-lib/not-allowed/not-allowed.component'
import { NotFoundComponent } from '@shared-ui-lib/not-found/not-found.component'
import { PageModule } from '@shared-ui-lib/page/page.module'
import { SnackbarModule } from '@shared-ui-lib/snackbar/snackbar.module'
import { LottieComponent, provideLottieOptions } from 'ngx-lottie'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'

registerLocaleData(localeDe, 'de')

const additionalImports: NgModule['imports'] = []

if (isDevelopment()) {
    additionalImports.push(
        StoreDevtoolsModule.instrument({ connectInZone: true })
    )
}

@NgModule({
    bootstrap: [AppComponent],
    declarations: [AppComponent, NotFoundComponent, NotAllowedComponent],
    imports: [
        BrowserModule,
        AuthModule,
        environment.modules.AnimationsModule,
        HeaderModule,
        LayoutModule,
        MatToolbarModule,
        MatSidenavModule,
        MaterialDayjsModule,
        MatInputModule,
        MatDatepickerModule,
        MatIconModule,
        MatButtonModule,
        MatListModule,
        MatSnackBarModule,
        CookieModule,
        TermsConditionsModule,
        IncompatibleBrowserModule,
        PortalModule,
        SnackbarModule,
        StoreModule.forRoot({}),
        SidebarModule,
        EffectsModule.forRoot(),
        TranslationModule.forRoot(),
        ContentModule,
        ErrorPanelModule,
        ButtonModule,
        AnalyticsModule,
        LottieComponent,
        ...additionalImports,
        NotificationPanelModule,
        NotificationStoreModule,
        GroupManagementStoreModule,
        PageModule,
        GtmModule,
        AppRoutingModule,
        FooterModule,
    ],
    providers: [
        {
            provide: LOCALE_ID,
            useValue: 'en',
        },
        {
            multi: true,
            provide: HTTP_INTERCEPTORS,
            useClass: TokenRefreshInterceptorService,
        },
        {
            multi: true,
            provide: HTTP_INTERCEPTORS,
            useClass: AcceptLanguageInterceptor,
        },
        {
            multi: true,
            provide: HTTP_INTERCEPTORS,
            useClass: TokenAndGroupIdsInterceptor,
        },
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler,
        },
        {
            provide: MAT_SELECT_CONFIG,
            useValue: { disableOptionCentering: true },
        },
        provideLottieOptions({
            player: () => import('lottie-web'),
        }),
        provideHttpClient(withInterceptorsFromDi()),
    ],
})
export class AppModule {}
