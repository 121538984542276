import { CommonModule } from '@angular/common'
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { AuthRoutingModule } from '@auth-ui-lib/auth-routing.module'
import { AuthStorageService } from '@auth-util-lib/auth-storage.service'
import { LoadingModule } from '@loading-lib/loading.module'
import { PageModule } from '@shared-ui-lib/page/page.module'
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc'
import { CallbackComponent } from './callback/callback.component'
import { LogoutModule } from './logout/logout.module'
import { UnregisteredModule } from './unregistered/unregistered.module'

@NgModule({
    declarations: [CallbackComponent],
    exports: [],
    imports: [
        AuthRoutingModule,
        CommonModule,
        LoadingModule,
        LogoutModule,
        OAuthModule.forRoot(),
        PageModule,
        UnregisteredModule,
    ],
    providers: [
        { provide: OAuthStorage, useClass: AuthStorageService },
        provideHttpClient(withInterceptorsFromDi()),
    ],
})
export class AuthModule {}
