import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { LottieComponent as NgxLottieComponent } from 'ngx-lottie'
import { LottieComponent } from './lottie.component'

@NgModule({
    declarations: [LottieComponent],
    imports: [CommonModule, NgxLottieComponent],
    exports: [LottieComponent],
})
export class LottieModule {}
